$white:#fff;
$black:#000;
$default-color: $white;

$color1:#222222;
$color2:#FAFAFA;
$color3:#777777;
$color4:#F36041;
$color5:#4FA93F;
$color6:#45CBE2;
$color7:#444444;
$color8:#BBBBBB;
$color9:#F1F1F1;
$color10:#666666;
$color11:#F6866D;
$color12:#D6D6D6;
$color13:rgba(136,136,136,0.29);
$color14:#CCCCCE;
$color15:#888888;
$color16:#ACACAC;
$color17:#3B5999;
$color18:#ff0;
$color19:$color16;
$color20:$color4;
$color21:rgb(218, 39, 31);
$color22:$color6;
$color23:#183885;


$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  1200px !default;
$screen-lg:                  1400px !default;
